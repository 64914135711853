<template>
  <div class="task_container">
    <label>
      <input
        type="checkbox"
        v-model="is_marked"
        v-on="{ change: clickable ? change_task : function () {} }"
      />
    </label>
    <div v-if="!is_changing" class="task">
      <p @click="is_changing = true">{{ name }}</p>
      <button class="mini_buttons delete" @click="delete_task">
        <font-awesome-icon icon="fa-regular fa-trash-can" />
      </button>
    </div>
    <textarea
      v-if="is_changing"
      class="input_field"
      v-model="name"
      @change="change_task()"
      autofocus
      @keydown.enter="change_task"
    />
  </div>
</template>

<script>
export default {
  props: ["item"],
  data() {
    return {
      is_marked: this.item.is_marked,
      is_changing: false,
      name: this.item.name,
      clickable: true,
    };
  },
  methods: {
    async change_task() {
      this.clickable = false;
      if (this.name === "") {
        this.$emit("error", "Name must be!");
        this.clickable = true;
        return;
      }
      this.$emit("update_task", this.item.id, this.name, this.is_marked);
      this.is_changing = false;
      setTimeout(() => {
        this.clickable = true;
      }, 500);
    },
    delete_task() {
      this.$emit("delete_task", this.item.id);
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/colors.scss";
label {
  margin-right: 7px;
}
p {
  font-size: 18px;
  color: var(--background);
}
textarea {
  font-family: "Inter", sans-serif;
  max-height: 26px;
  cursor: default;
}
.input_field {
  border-bottom: 1px solid var(--accent);
}
.task {
  border-bottom: 1px solid var(--secondary);
  width: 100%;
  cursor: pointer;
  display: flex;

  p {
    color: var(--default);
    font-size: 20px;

    margin: 0;
  }

  .mini_buttons {
    margin-left: 15px;
  }
}
.task_container {
  display: flex;
  margin-bottom: 25px;

  :hover .mini_buttons {
    display: block;
  }
}
</style>
