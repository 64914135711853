<template>
  <div>
    <div v-if="!is_changing" class="skill_container">
      <div class="name_container">
        <p @click="show_des">{{ name }}</p>
        <button class="mini_buttons change" @click="is_changing = true">
          <font-awesome-icon icon="fa-regular fa-pen-to-square" />
        </button>
        <button class="mini_buttons delete" @click="delete_skill()">
          <font-awesome-icon icon="fa-regular fa-trash-can" />
        </button>
      </div>

      <div
        :class="{ hide: is_description }"
        class="description_container"
        @click="show_des"
      >
        <div class="description" :class="{ full_description: is_showing_des }">
          {{ description }}
        </div>
      </div>
    </div>

    <div v-else>
      <input
        v-model="name"
        @blur="change_skill"
        @keydown.enter="change_skill"
        class="input_field"
        autofocus
      />
      <textarea
        v-model="description"
        @blur="change_skill"
        @keydown.enter="change_skill"
        class="input_description"
      />
    </div>
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
export default {
  props: ["item"],
  data() {
    return {
      error_message: "",
      is_showing_des: false,
      is_changing: false,
      name: this.item.name,
      description: this.item.description,
      // naming 11/10
    };
  },
  methods: {
    async change_skill() {
      this.is_changing = !this.is_changing;
      if (this.name === "") {
        alert("The name field is required.");
        this.is_changing = true;
        return;
      }

      const response = await fetch(
        process.env.VUE_APP_API_BASE_URL + "/skills/" + this.item.id,
        {
          method: "PUT",
          body: JSON.stringify({
            name: this.name,
            description: this.description,
          }),
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + VueCookies.get("auth-token"),
          },
        }
      );
      const responseJSON = await response.json();
      if (!response.ok) {
        this.$emit("error", responseJSON);
        this.is_changing = true;
        return;
      }
      this.is_changing = false;
    },
    delete_skill() {
      this.$emit("delete_skill", this.item.id);
    },
    show_des() {
      this.is_showing_des = !this.is_showing_des;
    },
  },
  computed: {
    is_description() {
      return this.description === null || this.description === "";
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/colors.scss";
.input_field {
  padding-left: 5px;
  width: 100%;
}
</style>
