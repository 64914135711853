import Vue from "vue";
import App from "./App.vue";
import router from "./router";

import { library } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";

import { faUserSecret } from "@fortawesome/free-solid-svg-icons";

import { faTrashCan } from "@fortawesome/free-regular-svg-icons";

import { faPenToSquare } from "@fortawesome/free-regular-svg-icons";

import { faFlagCheckered } from "@fortawesome/free-solid-svg-icons";

import { faEye } from "@fortawesome/free-regular-svg-icons";

import { faEyeSlash } from "@fortawesome/free-regular-svg-icons";

library.add(faUserSecret);
library.add(faTrashCan);
library.add(faPenToSquare);
library.add(faFlagCheckered);
library.add(faEye);
library.add(faEyeSlash);

Vue.component("font-awesome-icon", FontAwesomeIcon);

Vue.config.productionTip = false;

new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
