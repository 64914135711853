<template>
  <div @keydown.enter="reset_password">
    <error_message_component ref="error_message_component">
    </error_message_component>

    <div class="header">
      <div>Send To Email</div>
    </div>
    <div class="box">
      <div class="container">
        <div class="message">{{ message }}</div>
        <div>Email:</div>
        <input class="input_field" v-model="email" type="email" />
        <button class="add_button" v-if="clickable" @click="reset_password">
          Reset
        </button>
        <div v-if="!clickable">
          If you don't get an email try again after {{ remaining_time }} sec.
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import error_message_component from "@/components/error_message_component";
export default {
  components: { error_message_component },
  data() {
    return {
      email: "",
      message:
        "Please, put your actual email to get the link to reset password.",
      clickable: true,
      remaining_time: 60,
    };
  },
  methods: {
    async reset_password() {
      if (!this.email) {
        this.$refs.error_message_component.show_errors("Please, put email!");
        return;
      }
      const response = await fetch(
        process.env.VUE_APP_API_BASE_URL + "/user/forgot-password",
        {
          method: "POST",
          body: JSON.stringify({
            email: this.email,
          }),
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      ).catch(() => {
        this.$refs.error_message_component.show_errors("Failed to connect");
      });
      const responseJSON = await response.json();
      if (response.ok) {
        this.clickable = false;
        this.start_timer();
        this.message =
          "Link to reset password is successfully sent! If you don't see an email, please check spam.";
        setTimeout(() => {
          this.clickable = true;
        }, 60000);
        return;
      }
      this.$refs.error_message_component.show_errors(responseJSON.error);
    },
    start_timer() {
      this.intervalId = setInterval(() => {
        this.remaining_time--;

        if (this.remaining_time <= 0) {
          clearInterval(this.intervalId);
        }
      }, 1000);
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "src/styles/mobile_version.scss";

.box {
  color: var(--default);
}

.header {
  display: flex;
  height: 100px;
  width: 870px;
  margin: 0 auto;
  background-color: var(--accent_background);
  color: var(--default_highlight);
  justify-content: center;
  align-items: center;
  font-family: "Rubik", sans-serif;
  font-size: 48px;
  border-radius: 5px 5px 0 0;
  position: relative;
  top: 50px;
  padding: 0 15px;
}

.container {
  font-size: 18px;
  margin-top: 25px;

  div {
    margin-bottom: 5px;
  }

  a {
    text-decoration: underline;
    color: var(--accent);
    cursor: pointer;
  }

  .message {
    display: flex;
    justify-content: center;
    flex-direction: row;
    margin-bottom: 25px;
    font-size: 23px;
  }
}

.add_button {
  margin-bottom: 20px;
}

input {
  margin-bottom: 20px;
}

.wrapper {
  display: flex;

  svg {
    margin-left: 5px;
    cursor: pointer;
  }
}
</style>
