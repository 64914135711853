<template>
  <div class="box">
    <error_message_component
      ref="error_message_component"
    ></error_message_component>

    <div class="container">
      <div v-if="!is_adding" class="no_active_task">
        {{ toggle_greeting_text }}
      </div>
      <div v-for="skill in skills" v-bind:key="skill.id">
        <skill_row_component
          :item="skill"
          @delete_skill="delete_skill"
          @error="$refs.error_message_component.show_errors"
        ></skill_row_component>
      </div>

      <div v-if="is_adding">
        <input
          v-model="name"
          class="input_field input_field_skill"
          autofocus
          v-on:keyup.enter="clickable ? store() : function () {}"
        />
        <textarea
          v-model="description"
          class="input_description"
          v-on:keyup.enter="clickable ? store() : function () {}"
        />
      </div>
      <button
        class="add_button"
        v-on="{ click: clickable ? store : function () {} }"
      >
        {{ toggle_message }}
      </button>
    </div>
  </div>
</template>

<script>
import skill_row_component from "@/components/skill_row_component";
import error_message_component from "@/components/error_message_component";
import VueCookies from "vue-cookies";

export default {
  components: {
    error_message_component,
    skill_row_component,
  },
  data() {
    return {
      skills: [],
      is_adding: false,
      name: "",
      description: "",
      clickable: true,
      loaded: false,
    };
  },
  async created() {
    const response = await fetch(process.env.VUE_APP_API_BASE_URL + "/skills", {
      headers: {
        Authorization: "Bearer " + VueCookies.get("auth-token"),
      },
    });
    const responseJSON = await response.json();
    if (!response.ok) {
      if (VueCookies.get("auth-token") !== null) {
        VueCookies.remove("auth-token");
      }
      await this.$router.push({ path: "/" });
      return;
    }
    this.skills = responseJSON.skills;
    this.loaded = true;
  },
  methods: {
    async add_item() {
      this.clickable = false;
      const response = await fetch(
        process.env.VUE_APP_API_BASE_URL + "/skills",
        {
          method: "POST",
          body: JSON.stringify({
            name: this.name,
            description: this.description,
          }),
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + VueCookies.get("auth-token"),
          },
        }
      );
      const responseJSON = await response.json();
      if (!response.ok) {
        this.$refs.error_message_component.show_errors(responseJSON.message);
        this.clickable = true;
        return;
      }
      this.skills.push(responseJSON);
      this.name = "";
      this.description = "";
      this.is_adding = false;
      this.clickable = true;
    },
    store() {
      if (this.name !== "") {
        this.add_item();
        this.is_adding = false;
        return;
      } else if (this.description !== "" && this.is_adding) {
        this.$refs.error_message_component.show_errors("Put a name");
        return;
      }
      this.is_adding = !this.is_adding;
    },
    async delete_skill(db_id) {
      const response = await fetch(
        process.env.VUE_APP_API_BASE_URL + "/skills/" + db_id,
        {
          method: "DELETE",
          headers: {
            "Content-type": "application/json",
            Authorization: "Bearer " + VueCookies.get("auth-token"),
          },
        }
      );
      if (!response.ok) {
        const responseJSON = await response.json();
        this.$refs.error_message_component.show_errors(responseJSON.message);
        return;
      }
      this.skills = this.skills.filter((skill) => skill.id !== db_id);
    },
  },
  computed: {
    toggle_message() {
      return this.is_adding === false ? "New Skill" : "Save New Skill";
    },
    toggle_greeting_text() {
      if (!this.skills.length && this.loaded) {
        return (
          "Let me help you: 1) You can breathe (breathing - is the physical process\n" +
          "of inhaling oxygen and exhaling carbon dioxide )\n" +
          "Now that you know it - add this wonderful skill!"
        );
      } else {
        return "";
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/colors.scss";
.add_button {
  margin-left: 18px;
}
.input_field_skill {
  font-size: 18px;
  padding-left: 5px;
  border-bottom: 1px solid var(--accent);
  width: 100%;
}
</style>
