<template>
  <div @keydown.enter="login">
    <error_message_component ref="error_message_component">
    </error_message_component>

    <div class="header">
      <div>Login</div>
    </div>
    <div class="box">
      <div class="container">
        Email:
        <input class="input_field" v-model="email" type="email" />
        Password:
        <div class="wrapper">
          <input :type="input_type" v-model="password" class="input_field" />
          <font-awesome-icon
            icon="fa-regular fa-eye"
            @click="show_password"
            v-if="!showing_password"
          />
          <font-awesome-icon
            icon="fa-regular fa-eye-slash"
            @click="show_password"
            v-if="showing_password"
          />
        </div>
        <button class="add_button" @click="login">Login</button>
        <div>
          If you forget password
          <router-link to="/send_link">click here</router-link>
        </div>

        <div>If you don't have account, please</div>
        <router-link to="/register">Register</router-link>
      </div>
    </div>
  </div>
</template>
<script>
import VueCookies from "vue-cookies";
import error_message_component from "@/components/error_message_component";
export default {
  components: { error_message_component },
  data() {
    return {
      email: "",
      password: "",
      showing_password: false,
    };
  },
  async created() {
    if (VueCookies.get("auth-token") !== null) {
      await this.$router.push({ path: "todo" });
    }
  },
  methods: {
    async login() {
      if (!this.email || !this.password) {
        this.$refs.error_message_component.show_errors(
          "Please, put password and email!"
        );
        return;
      }
      const response = await fetch(
        process.env.VUE_APP_API_BASE_URL + "/user/login",
        {
          method: "POST",
          body: JSON.stringify({
            email: this.email,
            password: this.password,
          }),
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
          },
        }
      ).catch(() => {
        this.$refs.error_message_component.show_errors("Failed to connect");
      });

      const responseJSON = await response.json();
      if (!response.ok) {
        this.$refs.error_message_component.show_errors(responseJSON.error);
        return;
      }
      VueCookies.set("auth-token", responseJSON);
      await this.$router.push({ path: "todo" });
    },
    show_password() {
      this.showing_password = !this.showing_password;
    },
  },
  computed: {
    input_type() {
      return this.showing_password ? "text" : "password";
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/colors.scss";
@import "src/styles/mobile_version.scss";

.box {
  color: var(--default);
}

.header {
  display: flex;
  height: 100px;
  width: 870px;
  margin: 0 auto;
  background-color: var(--accent_background);
  color: var(--default_highlight);
  justify-content: center;
  align-items: center;
  font-family: "Rubik", sans-serif;
  font-size: 48px;
  border-radius: 5px 5px 0 0;
  position: relative;
  top: 50px;
  padding: 0 15px;
}

.container {
  font-size: 18px;

  margin-top: 25px;

  div {
    margin-bottom: 5px;
  }

  a {
    text-decoration: underline;
    color: var(--accent);
    cursor: pointer;
  }
}

.add_button {
  margin-bottom: 20px;
}

input {
  margin-bottom: 20px;
}

.wrapper {
  display: flex;

  svg {
    margin-left: 5px;
    cursor: pointer;
  }
}
</style>
