<template>
  <div class="box">
    <error_message_component
      ref="error_message_component"
    ></error_message_component>

    <div class="container">
      <div class="active_tasks">
        <div v-if="!is_adding" class="no_active_task">
          {{ toggle_greeting_text }}
        </div>
        <div v-for="task in unmarked_sort" v-bind:key="task.id">
          <task_component
            :item="task"
            @delete_task="delete_task"
            @update_task="update_task"
            @error="$refs.error_message_component.show_errors"
          ></task_component>
        </div>

        <div v-if="is_adding" class="new_task">
          <label class="form-control">
            <input type="checkbox" />
          </label>
          <input
            v-model="new_task"
            class="input_field"
            @blur="store"
            autofocus
          />
        </div>
        <button class="add_button" @click="store" v-if="!is_adding">
          New task
        </button>
      </div>

      <div class="finished_tasks">
        <div v-if="is_showing_list">
          <div v-for="task in marked_sort" :key="task.id">
            <task_component
              :item="task"
              @delete_task="delete_task"
              @update_task="update_task"
            ></task_component>
          </div>
        </div>
        <h2 @click="toggle_task">
          {{ toggle_message }}({{ marked_sort.length }}) tasks
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import task_component from "@/components/task_component";
import error_message_component from "@/components/error_message_component";
import VueCookies from "vue-cookies";

export default {
  components: {
    error_message_component,
    task_component,
  },
  data() {
    return {
      new_task: "",
      tasks: [],
      is_showing_list: false,
      is_adding: false,
      loaded: false,
    };
  },
  async created() {
    const response = await fetch(process.env.VUE_APP_API_BASE_URL + "/tasks", {
      headers: {
        Authorization: "Bearer " + VueCookies.get("auth-token"),
      },
    });
    const responseJSON = await response.json();
    if (!response.ok) {
      if (VueCookies.get("auth-token") !== null) {
        VueCookies.remove("auth-token");
      }
      await this.$router.push({ path: "/" });
      return;
    }
    this.tasks = responseJSON.tasks;
    this.loaded = true;
  },
  methods: {
    async add_item() {
      if (this.new_task.length > 255) {
        this.$refs.error_message_component.show_errors(
          "Please note that the Todo must not exceed 255 characters in length. "
        );
        return;
      }
      const response = await fetch(
        process.env.VUE_APP_API_BASE_URL + "/tasks",
        {
          method: "POST",
          body: JSON.stringify({ name: this.new_task }),
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + VueCookies.get("auth-token"),
          },
        }
      );
      const responseJSON = await response.json();
      if (!response.ok) {
        this.$refs.error_message_component.show_errors(responseJSON.message);
        return;
      }
      responseJSON.is_marked = 0;
      this.tasks.push(responseJSON);
      this.new_task = "";
      this.is_adding = false;
    },
    store() {
      if (this.new_task !== "") {
        this.add_item();
      }
      this.is_adding = !this.is_adding;
    },
    toggle_task() {
      this.is_showing_list = !this.is_showing_list;
    },
    async delete_task(db_id) {
      const response = await fetch(
        process.env.VUE_APP_API_BASE_URL + "/tasks/" + db_id,
        {
          method: "DELETE",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + VueCookies.get("auth-token"),
          },
        }
      );
      if (!response.ok) {
        const responseJSON = await response.json();
        this.$refs.error_message_component.show_errors(responseJSON.message);
        return;
      }
      this.tasks = this.tasks.filter((task) => task.id !== db_id);
    },
    async update_task(id, name, is_marked) {
      const response = await fetch(
        process.env.VUE_APP_API_BASE_URL + "/tasks/" + id,
        {
          method: "PUT",
          body: JSON.stringify({
            name: name,
            is_marked: is_marked ? 1 : 0,
          }),
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + VueCookies.get("auth-token"),
          },
        }
      );
      const responseJSON = await response.json();
      if (!response.ok) {
        this.$refs.error_message_component.show_errors(responseJSON.message);
        return;
      }

      const index = this.tasks.findIndex((task) => task.id === id);
      this.tasks[index].name = responseJSON.name;
      this.tasks[index].is_marked = responseJSON.is_marked;
    },
  },
  computed: {
    unmarked_sort() {
      return this.tasks.filter((item) => item.is_marked !== 1);
    },
    marked_sort() {
      return this.tasks.filter((item) => item.is_marked === 1);
    },
    toggle_message() {
      return this.is_showing_list === false
        ? "Show archived "
        : "Hide archived";
    },
    toggle_greeting_text() {
      if (!this.unmarked_sort.length && this.marked_sort.length) {
        return "Congratulations, you've finished everything! Now you can add more tasks or relax a bit";
      } else if (!this.tasks.length && this.loaded) {
        return "Hi! You can start by adding a few tasks or procrastinate more :D";
      } else {
        return "";
      }
    },
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/colors.scss";
.container {
  max-height: unset;
  height: 100%;
}
.active_tasks {
  margin-bottom: 50px;
  .add_button {
    margin-left: 35px;
  }
}
.finished_tasks {
  display: flex;
  flex-direction: column;
  color: var(--default);
  padding-bottom: 50px;
  border-top: 1px var(--default) dashed;
  width: auto;
  padding-top: 25px;
  margin-top: auto;

  .task_container {
    text-decoration: line-through var(--default);
    margin-bottom: 20px;
  }
}
h2 {
  margin: 2px 0 0 35px;
  font-family: "Inter", sans-serif;
  font-size: 16px;
  line-height: 19px;
  height: 19px;
  cursor: pointer;
  width: fit-content;
  border: 1px solid transparent;
  padding: 2px 8px;
}

h2:hover {
  border: 1px solid var(--secondary);
  padding: 2px 8px;
}
.new_task {
  display: flex;

  .input_field {
    padding: 0 0 2px 0;
  }
}
</style>
