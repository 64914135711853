<template>
  <div>
    <div class="name_container">
      <p @click="is_showing_des = !is_showing_des">{{ item.name }}</p>
      <button class="mini_buttons" @click="$emit('delete_skill_to_remind')">
        <font-awesome-icon icon="fa-solid fa-flag-checkered" />
      </button>
    </div>

    <div
      :class="{ hide: item.description === null }"
      class="description_container"
      @click="is_showing_des = !is_showing_des"
    >
      <div class="description" :class="{ full_description: is_showing_des }">
        {{ item.description }}
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: ["item"],
  data() {
    return {
      is_marked: false,
      is_showing_des: false,
    };
  },
};
</script>
<style scoped lang="scss">
@import "@/styles/colors.scss";
</style>
