<template>
  <div class="error_message" :class="{ hide: !showing_message }">
    {{ error_message }}
  </div>
</template>
<script>
export default {
  data() {
    return {
      showing_message: false,
      error_message: "",
    };
  },
  methods: {
    show_errors(message) {
      this.error_message = message;
      this.showing_message = true;
      setTimeout(() => {
        this.showing_message = false;
      }, 2500);
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/styles/colors.scss";
.error_message {
  top: 2px;
  display: flex;
  border-radius: 4px;
  background-color: var(--background_box);
  transform: translateX(-50%);
  padding: 10px 0;
  color: var(--background);
  position: absolute;
  left: 50%;
  z-index: 1;
  font-family: "Varela Round", sans-serif;
  opacity: 100%;
  transition: 0.3s;
  width: 60%;
  justify-content: center;
}
.hide {
  opacity: 0;
}
</style>
