<template>
  <div>
    <nav :class="{ hide: hide_nav }">
      <router-link to="/todo">
        <div>{{ TODO }}</div>
      </router-link>
      <router-link to="/skills">
        <div>
          {{ Skills }}
        </div>
      </router-link>
      <router-link to="/repeat">
        <div>
          {{ Repeat }}
        </div>
      </router-link>
    </nav>
    <div class="border" :class="{ hide: hide_nav }"></div>

    <router-view />
    <a class="log_out" v-on:click="log_out" :class="{ hide: hide_nav }"
      >Log Out</a
    >
  </div>
</template>

<script>
import VueCookies from "vue-cookies";
export default {
  computed: {
    Repeat() {
      if (this.$route.name === "R") {
        return "Repeat";
      }
      return "R";
    },
    Skills() {
      if (this.$route.name === "S") {
        return "Skills";
      }
      return "S";
    },
    TODO() {
      if (this.$route.name === "T") {
        return "TODO";
      }
      return "T";
    },
    hide_nav() {
      return (
        this.$route.name === "L" ||
        this.$route.name === "Reg" ||
        this.$route.name === "New" ||
        this.$route.name === "Send"
      );
    },
  },
  methods: {
    async log_out() {
      VueCookies.remove("auth-token");
      await this.$router.push({ path: "/" });
    },
  },
};
</script>

<style lang="scss">
@import "src/styles/style.scss";
@import "@/styles/colors.scss";
@import "src/styles/mobile_version.scss";
@import url("https://fonts.googleapis.com/css2?family=Inter&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Varela+Round&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter&family=Rubik:wght@700&family=Varela+Round&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@700&family=Rubik:wght@700&family=Varela+Round&display=swap");

body {
  font-family: "Varela Round", sans-serif;
}
nav {
  display: flex;
  height: 100px;
  width: 870px;
  margin: 0 auto;
  background-color: var(--accent_background);
  color: var(--default_highlight);
  align-items: center;
  justify-content: center;
  font-family: "Rubik", sans-serif;
  font-size: 48px;
  border-radius: 5px 5px 0 0;
  position: relative;
  top: 50px;
  padding: 0 15px;

  a {
    text-decoration: none;
    color: var(--default);
    border-left: 2px solid var(--default);
    padding: 0 15px;
    transition: flex-grow 0.3s linear;
    text-align: center;
  }

  a:first-child {
    border-left: 0;
  }

  .router-link-exact-active {
    flex-grow: 1;
    border-left: 0;

    + a {
      border-left: 0;
    }

    div {
      color: var(--default_highlight);
      display: block;
      font-family: "Rubik", sans-serif;
      font-size: 48px;
      position: absolute;
      bottom: 50%;
      left: 50%;
      margin: 0;
      transform: translateX(-50%) translateY(50%);
    }
  }
}
.border {
  height: 1px;
  width: 900px;
  margin: 0 auto;
  transform: translateY(50px);
  background: linear-gradient(
    90deg,
    var(--gradient) 0%,
    var(--default) 53.65%,
    var(--gradient) 100%
  );
}
.log_out {
  position: absolute;
  top: 15px;
  right: 20px;
  padding: 2px 10px;
  text-decoration: underline;
  color: var(--default_highlight);
  cursor: pointer;

  opacity: 0.7;
}
.log_out:hover {
  opacity: 1;
}
</style>
