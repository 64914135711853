<template>
  <div class="box">
    <div class="container">
      <error_message_component ref="error_message_component">
      </error_message_component>
      <div class="timer">
        Time until next update: {{ days }} day, {{ hours }} hours, {{ minutes }}
        minutes
      </div>
      <div
        v-for="remind_skill in remind_skills"
        :key="remind_skill.id"
        class="skill_container"
      >
        <repeat_component
          :item="remind_skill.skill"
          @delete_skill_to_remind="delete_skill(remind_skill.id)"
        ></repeat_component>
      </div>
    </div>
  </div>
</template>
<script>
import repeat_component from "@/components/repeat_component";
import error_message_component from "@/components/error_message_component";
import VueCookies from "vue-cookies";

export default {
  components: { error_message_component, repeat_component },
  data() {
    return {
      remind_skills: [],
      days: null,
      hours: null,
      minutes: null,
      currentDate: new Date(),
    };
  },
  async created() {
    const response = await fetch(
      process.env.VUE_APP_API_BASE_URL + "/remind_skills",
      {
        headers: {
          Authorization: "Bearer " + VueCookies.get("auth-token"),
        },
      }
    );
    if (!response.ok) {
      if (VueCookies.get("auth-token") !== null) {
        VueCookies.remove("auth-token");
      }
      await this.$router.push({ path: "/" });
      return;
    }
    const responseJSON = await response.json();
    this.remind_skills = responseJSON.remind_skills;
    this.timer();
  },
  methods: {
    async delete_skill(db_id) {
      const response = await fetch(
        process.env.VUE_APP_API_BASE_URL + "/remind_skills/" + db_id,
        {
          method: "DELETE",
          headers: {
            "Content-type": "application/json",
            Accept: "application/json",
            Authorization: "Bearer " + VueCookies.get("auth-token"),
          },
        }
      );
      if (!response.ok) {
        const responseJSON = await response.json();
        this.$refs.error_message_component.show_errors(responseJSON.message);
        return;
      }
      this.remind_skills = this.remind_skills.filter(
        (skill) => skill.id !== db_id
      );
    },
    timer() {
      let array = [1, 0, 2, 1, 0, 3, 2];
      let currentDate = new Date();
      this.days = array[currentDate.getUTCDay()];
      this.hours = 24 - currentDate.getUTCHours() - 1;
      this.minutes = 60 - currentDate.getUTCMinutes();
      setInterval(() => {
        this.timer();
      }, 60000);
    },
  },
};
</script>
<style lang="scss">
@import "@/styles/colors.scss";
.timer {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  color: var(--default);
  margin: 0 auto 30px;
}
</style>
