import Vue from "vue";
import VueRouter from "vue-router";
import TaskSection from "../views/task_section";
import SkillsSection from "../views/skills_section";
import RepeatSection from "../views/repeat_section";
import LoginSection from "../views/login_section";
import RegisterSection from "../views/register_section";
import Reset_password_section from "../views/new_password_section";
import Send_link_to_reset from "@/views/send_link_to_reset";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    // nice name, 10/10
    name: "L",
    component: LoginSection,
  },
  {
    path: "/new_password",
    // nice name, 10/10
    name: "New",
    component: Reset_password_section,
  },
  {
    path: "/send_link",
    // nice name, 10/10
    name: "Send",
    component: Send_link_to_reset,
  },
  {
    path: "/register",
    // nice name, 10/10
    name: "Reg",
    component: RegisterSection,
  },
  {
    path: "/todo",
    // nice name, 10/10
    name: "T",
    component: TaskSection,
  },
  {
    path: "/repeat",
    // nice name, 10/10
    name: "R",
    component: RepeatSection,
  },
  {
    path: "/skills",
    // nice name, 10/10
    name: "S",
    component: SkillsSection,
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
